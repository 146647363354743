/// <reference path="GamesFlashQuiz.js" />
/// <reference path="UrlTemplate.js" />
/// <reference path="TextResource.js" />
/// <reference path="gameUtils/EasierLevel.js" />

var GamesLeveledFlashQuiz = Class.create(GamesFlashQuiz, {
	initialize: function($super, el, options) {
		$super(el, options);
		this.level = this.options.level;
		this.medalMinLevel = this.options.medalMinLevel;
		this.progress = this.options.progress;
		this.setLevelInformation();

		this.blinkLength = this.options.blinkLength;
		this.nrOfBlinks = this.options.nrOfBlinks;
		this.isArcade = this.options.isArcade;

		this.screenId = '#levelUpScreen';
		this.medalBox = $(this.options.medalBox).down('img');
		this.levelText = $(this.options.levelText);
		this.progressBar = $(this.options.progressBar);
		this.menuBar = document.getElementsByClassName("menubar")[0];
		this.easierLevel = new EasierLevel(this.options, this.safeObserve.bind(this));

		if (options.nomedal == "true" || this.medalBox.src == "")
			this.medalBox.style.display = "none";

		this.setTaskText(new TextResource(this.options.trLoadingLevel).string());
		
		if (!document.querySelector(".mia-LevelUpScreen")) {
			new window.Vue({el: this.el.down(".mia-LegacyLevelUpScreen")});
		}
		waitForElementToBePresent(this.screenId).then(this.initializeLevelUpScreen.bind(this));
		this.easierLevel.initializeEasierLevel(this);
		waitForElementToBePresent(".mia-easierLevelConfirmButton").then(this.initializeLevelDown.bind(this));
	},

	initializeLevelUpScreen: function() {
		this.screenJQuery = jQuery(this.screenId);
		this.screenJQuery.find('.levelUpButton').on('click', this.onNextLevelOkClick.bind(this));
		this.screenJQuery.find('.lastLevelContinueButton').on('click', this.onNextLevelOkClick.bind(this));
		if (this.options.endingScreenShown) {
			this.showEndingScreen(this.options.endingScreenRobotSrc, this.options.endingScreenLevel, this.options.endingScreenMedalName, this.options.endingScreenAmountOfScoops);
		} else {
			this.hideLevelFinalScreen();
		}
	},

	setLevelInformation: function () {
		var nextMedalLevel = this.options.nextMedalLevel;
		if (nextMedalLevel > 0 && !(this.options.noNextMedal == "true")) {
			this.el.down('.nextMedalArea').style.display = "inline-block";
			this.el.down('.nextMedalText').innerHTML = 'after Level: ' + nextMedalLevel;
		}
		else {
			this.el.down('.nextMedalArea').style.display = "none";
		}
	},

	customLoadEvent: function (json) {
	},

	showPage: function () {
		this.updateProgressBar();
		this.levelText.innerHTML = 'Level: ' + this.json.level;
		this.easierLevel.initializeEasierLevel(this);
		var nextMedalLevel = this.json.nextMedalLevel;
		if (nextMedalLevel > 0 && !this.json.noNextMedal) {
			this.el.down('.nextMedalArea').style.display = "inline-block";
			var realNextMedalLevel = nextMedalLevel;
			if (realNextMedalLevel > 0 && realNextMedalLevel < this.json.level) {
				realNextMedalLevel = this.json.level;
			}
			this.el.down('.nextMedalText').innerHTML = 'after Level: ' + realNextMedalLevel;
		}
		else {
			this.el.down('.nextMedalArea').style.display = "none";
		}
	},

	initializeLevelDown: function(element) {
		this.safeObserve(element, 'click', this.onLevelDownClick.bindAsEventListener(this));
	},

	onNextLevelOkClick: function (ev) {
		if (typeof (ev) != 'undefined')
			ev.preventDefault();

		this.startNextLevel();
		this.handleProcessResult();
	},

	startNextLevel: function() {
		this.showPage();
		this.screenJQuery.find('.levelButtons').css("display", "flex");
		this.screenJQuery.find('.continueButton').css("display", "none");
		if (this.isArcade) {
			this.screenJQuery.find('.returnToArcadeButton').css("display", "none");
		} else {
			this.screenJQuery.find('.continueButton').css("display", "none");
		}
		this.hideLevelFinalScreen();
	},

	handleWrongAnswer: function() {
		var itemId = this.numberList[this.progress] | 0;
		if (this.progress > 0) {
			this.progress--;
			this.updateProgressBar();
		}

		var wrongAnswerPromise = new Promise(function(resolve, reject) {
			var url = new UrlTemplate(this.options.processAnswerUrl)
				.r('PLH_success', this.success)
				.r('PLH_Status', 2)
				.r('PLH_progress', this.progress)
				.r('PLH_itemId', itemId)
				.r('PLH_nquestion', -2).url;
			jQuery.ajax({ url: url }).done(resolve).fail(reject);
		}.bind(this));

		var blickingDelay = this.blinkLength * this.nrOfBlinks;
		new Promise(function (resolve) {
			setTimeout(resolve, blickingDelay);
		}).then(function() {
			this.setTaskText(new TextResource(this.options.trLoadingLevel).string());
			wrongAnswerPromise.then(this.onWrongAnswerSuccess.bind(this));
		}.bind(this));
	},

	onWrongAnswerSuccess: function(response) {
		this.json = response;
		if (this.json.numberList.length == 0 || this.json.textList[0] == undefined) {
			this.progress = 0;
			this.initFlashGame();
			return;
		}

		this.numberList = this.json.numberList;
		this.textList = this.json.textList;
		this.questionSoundPaths = this.json.questionSoundPaths;

		this.resetUI();
		this.setToClick(this.numberList[this.progress], this.textList[this.progress]);
		this.createAndReadAllTextToSpeech();
	},

	onSuccess: function (req) {
		var response = req.responseText.evalJSON();
		this.screenJQuery.find(".endingScreenButtons").css("display", "none");
		this.screenJQuery.find(".levelButtons").css("display", "flex");
		if (!this.isArcade && response.medalRecived) {
			this.showEndingScreen(response.medalRobotPictureSrc, this.json.level, response.medalName, response.amount);
			window.invalidateSelectedTask();
		} else if (response.medal != undefined && !response.medal.endsWith("games/medals/medal_none.svg")) {
			this.medalBox.src = this.json.medal;
			this.medalBox.style.display = "inline";
			this.screenJQuery.find('.header').text(new TextResource(this.options.trHeaderWithMedal).r("{number}", this.json.level).r("{medalType}", response.medalName).string());
			this.screenJQuery.find('.description').text(new TextResource(this.options.trDescriptionWithMedal).r("{number}", this.json.level).r('{coins}', response.amount).r("{medalType}", response.medalName).string());
			this.screenJQuery.find('.image').attr("src", response.robotPictureSrc);

			this.json.medal = "games/medals/medal_none.svg";
			if (this.json.challengeWasCompleted) {
				this.screenJQuery.find('.levelButtons').css("display", "none");
				this.screenJQuery.find('.challengeCompletedButtons').css("display", "inline-block");
			}
		} else {
			this.screenJQuery.find('.image').attr("src", response.robotPictureSrc);
			this.screenJQuery.find('.header').text(new TextResource(this.options.trHeaderWithoutMedal).r("{number}", this.json.level).string());
			this.screenJQuery.find('.description').text(new TextResource(this.options.trDescriptionWithoutMedal).r("{number}", this.json.level).r('{coins}', response.amount).string());
			this.screenJQuery.find('.levelButtons').css("display", "flex");
			if (this.isArcade) {
				this.screenJQuery.find('.returnToArcadeButton').css("display", "inline-block");
			}
		}
		this.json = response;

		var newLevel = 'Level: ' + this.json.level;

		//Level up
		if (this.progress == this.numberList.length && newLevel != this.levelText.innerHTML) {
			this.showLevelFinalScreen();
			this.removeAllListeners();
			this.updateProgressBar();
			if (this.json.amount == 0) {
				new Ajax.Request(new UrlTemplate(this.options.processAnswerUrl)
					.r('PLH_success', true)
					.r('PLH_Status', 2)
					.r('PLH_progress', this.progress)
					.r('PLH_itemId', -1)
					.r('PLH_nquestion', -2).url, {
					method: 'get',
					onSuccess: this.onSuccess.bind(this)
				});
				return;
			}
			this.progress = 0;
			this.numberList = this.json.numberList;
			this.textList = this.json.textList;
			this.questionSoundPaths = this.json.questionSoundPaths;
			this.levelText.innerHTML = newLevel;

			var nextMedalLevel = this.json.nextMedalLevel;
			if (nextMedalLevel > 0 && !this.json.noNextMedal) {
				this.el.down('.nextMedalText').innerHTML = 'after Level: ' + nextMedalLevel;
				this.el.down('.medalIcon').src = this.json.nextMedalImageSource;
				this.el.down('.nextMedalArea').style.display = "inline-block";
			}
			else {
				this.el.down('.nextMedalArea').style.display = "none";
			}
		}
	},
	onLevelJumpSuccess: function (req) {
		try {
			this.json = req.responseText.evalJSON();
		} catch (e) {
			global.handleFailedAJAJRequest();
			return;
		}
		this.success = true;
		this.handleProcessResult();
		this.el.down('.modal-header').down(".mia-img").click();
		this.startNextLevel();
	},
	onLevelDownClick: function(ev) {
		this.easierLevel.setEasierLevel(this.options.levelJumpUrl, this.onLevelJumpSuccess.bind(this));
	},
	showLevelFinalScreen: function() {
		this.el.down(".mia-ProgressSection").hide();
		this.el.down(".mia-svgGameContainer").hide();
		this.el.down(".questionContainer").hide();
		var lessonIntro = this.el.down(".mia-LessonIntro");
		if (lessonIntro) {
			lessonIntro.hide();
		}
		this.changeFeedbackButtonVisibility(false);
		this.screenJQuery.css("display", "flex");
	},
	hideLevelFinalScreen: function() {
		this.el.down(".mia-ProgressSection").show();
		this.el.down(".mia-svgGameContainer").show();
		this.el.down(".questionContainer").show();
		var lessonIntro = this.el.down(".mia-LessonIntro");
		if (lessonIntro) {
			lessonIntro.show();
		}
		this.changeFeedbackButtonVisibility(true);
		this.screenJQuery.css("display", "none");
	},
	showEndingScreen: function(robotSrc, level, medalName, amount) {
		this.showLevelFinalScreen();

		this.screenJQuery.find(".endingScreenButtons").css("display", "flex");
		this.screenJQuery.find(".levelButtons").css("display", "none");

		this.screenJQuery.find(".title").text(this.options.trCongratulations);
		this.screenJQuery.find(".image").attr("src", robotSrc);
		this.screenJQuery.find(".header").text(this.options.trHeaderWithMedal.replace("{number}", level).replace("{medalType}", medalName));
		this.screenJQuery.find(".description").text(this.options.trDescriptionWithMedal.replace("{number}", level).replace('{coins}', amount).replace("{medalType}", medalName));
		this.screenJQuery.find(".repeatButton").on("click", this.onRepeatMedalClick.bind(this));
	},
	onRepeatMedalClick: function(event) {
		event.preventDefault();
		new Ajax.Request(new UrlTemplate(this.options.endingScreenRepeatMedalUrl).url, {
			method: 'get',
			onSuccess: this.initFlashGame.bind(this)
		});
		this.hideLevelFinalScreen();
	},

	changeFeedbackButtonVisibility: function(show) {
		var feedbackButton = document.querySelector(".mia-GameFeedbackButton");

		if (feedbackButton !== null) {
			feedbackButton.style.display = show ? "flex" : "none";
		}
	}
});
