/// <reference path="../UrlTemplate.js" />
/// <reference path="../TextResource.js" />

EasierLevelInstance = null;

var EasierLevel = Class.create({
	initialize: function (options, safeObserve) {
		this.trEasierLevelButton = options.trEasierLevelButton;
		this.trEasierLevelModalDescription = options.trEasierLevelModalDescription;
		this.safeObserve = safeObserve;

		EasierLevelInstance = this;
	},
	setEasierLevel: function(url, onSuccess) {
		new Ajax.Request(new UrlTemplate(url).r('PLH_level', -1).url, {
			method: 'get',
			onSuccess: onSuccess.bind(this),
		});
	},
	updateModalTitle: function(element, previousLevel) {
		element.down(".title").innerText = new TextResource(EasierLevelInstance.trEasierLevelButton).r('{number}', previousLevel).string();
	},
	updateModalDescription: function(element, previousLevel) {
		element.down(".easierLevelText").innerText = new TextResource(EasierLevelInstance.trEasierLevelModalDescription).r('{number}', previousLevel).string()
	},
	updateEasierLevelButtonText: function(element, previousLevel) {
		element.innerText = new TextResource(EasierLevelInstance.trEasierLevelButton).r('{number}', previousLevel).string();
	},
	initializeEasierLevel: function(context) {
		waitForElementToBePresent(".mia-EasierLevelModal").then(EasierLevelInstance.initializeEasierLevelModal.bind(context));

		// The EasierLevelButton is shown only when the more-options menu is opened, and after it is closed, the element is deleted.
		// A click event listener helps to track the moment when the EasierLevelButton element exists in DOM.
		waitForElementToBePresent(".mia-GameSettingsMenu").then(function (element) {
			EasierLevelInstance.safeObserve(element, 'click', EasierLevelInstance.onGameSettingsMenuClick.bind(context, element));
		});
	},
	onGameSettingsMenuClick: function(element) {
		var easierLevelButtonElement = element.down(".mia-easierLevelButton");
		if (!easierLevelButtonElement) {
			return;
		}

		if (document.querySelector("#levelUpScreen").style.display === "none") {
			easierLevelButtonElement.style.display = "";
			EasierLevelInstance.initializeEasierLevelButton.call(this, easierLevelButtonElement);
			EasierLevelInstance.initializeEasierLevelButtonText.call(this, easierLevelButtonElement.down(".mia-easierLevelButtonText"));
		} else {
			easierLevelButtonElement.style.display = "none";
		}
	},
	initializeEasierLevelModal: function(element) {
		var previousLevel = this.level - 1;
		EasierLevelInstance.updateModalTitle(element, previousLevel);
		EasierLevelInstance.updateModalDescription(element, previousLevel);
	},
	initializeEasierLevelButton: function(element) {
		element.style.display = this.level > this.medalMinLevel ? "" : "none";
	},
	initializeEasierLevelButtonText: function(element) {
		EasierLevelInstance.updateEasierLevelButtonText(element, this.level - 1)
	},
});
