/// <reference path="DOMAttached.js" />
/// <reference path="GamesLeveledFlashQuiz.js" />
/// <reference path="ProgressIndicatingControl.js" />

var GamesFlashlessGeography = Class.create(GamesLeveledFlashQuiz, {
	initialize: function ($super, el, options) {
		$super(el, options);
		
		this.showProgressIndicator(undefined, "#" + this.options.frame, true);
		if (typeof (console) != "undefined") {
			console.log("GamesFlashlessGeography.initialize", this);
		}

		// assign to global variable (to toggle feedback sound)
		GamesQuizInstance = this;

		// Parameters
		this.playFeedbackSound = this.options.feedbackSound == "on" ? true : false;
		this.fillColorsEnum = Object.freeze({ "regular": 1, "hover": 2, "correct": 3, "incorrect": 4 });

		// vars
		this._toClick = null;
		this._gameInitialized = false;
		this._stateArray = new Array();
		this._blinkCounter = 0;
		this._itemArray = this.options.ItemArray.split(',');
		this._geoIdsArray = this.options.GeoIdsArray.split(',');

		if (this._itemArray === 'undefined' || this._itemArray === null) {
			confirmAI(this.options.couldNotLoadText, this.onConfirmOkButtonClick, function(){}, true, false);
		}

		// enable scaling of content for geography-styled games as some clickable items
		// have very small clickable area and it is required to zoom in on it on touchscreen devices
		jQuery('meta[name=viewport]').attr('content', 'width=device-width, initial-scale=1');
		
		document.getElementById(this.options.objectId).addEventListener("load", function () {
			this.initializeScript();
	   	}.bind(this));
	},

	onConfirmOkButtonClick: function() {
		history.back();
	},

	fillShape: function (shape, fillColor) {
		// Fill the shape only when classic coloring is turned on.
		//
		// The idea of classical coloring used to be to fill the shapes of the geography map with theme dependent colors.
		// There were 4 theme dependant colors for filling the shapes: regular(pink for AI, brown for CD), 
		// hover(brighter regular), correct and incorrect colors.
		//
		// Now as the games graphic has become more complex(the shape might have children elements with different colors) 
		// filling of the shape with a single color doesn't work anymore. Thus instead, we are getting the current fill color 
		// of the shape and brighten or darken it to get visually different color.
		// 
		// The classic coloring naming is not valid anymore, fill free to change it
		// (haven't been done from the start as this requires changes both on MiaEdit and website).
		if (!this.options.classicColoring) {
			return;
		}
		
		if (shape.hasChildNodes()) {
			for (var child = shape.firstChild; child !== null; child = child.nextSibling) {
				this.fillShape(child, fillColor);
			}
		} else {
			if (shape.nodeName !== "g") {
				if (shape.style) {
					var color = null;
					if (fillColor === this.fillColorsEnum.regular) {
						color = this.getRegularColor(shape);
					} else if (fillColor === this.fillColorsEnum.hover) {
						color = this.getColorFromRegular(shape, "hover", 50);
					} else {
						// Do nothing on correct or incorrect color fill.
					}

					if (color) {
						shape.style.fill = color;
					}
				}
			}
		}
	},

	// Get current fill color of the shape.
	getRegularColor: function (shape) {
		var regularColor = shape.getAttribute("data-regular-color");
		if (regularColor) {
			return regularColor;
		}

		regularColor = window.getComputedStyle(shape, null).fill;
		shape.setAttribute("data-regular-color", regularColor);
		return regularColor;
	},
	
	getColorFromRegular: function (shape, key, amount) {
		var color = shape.getAttribute("data-" + key + "-color");
		if (color) {
			return color;
		}

		var regularColor = this.getRegularColor(shape);
		if (regularColor[0] !== "#") {
			regularColor = this.rgbToHex(regularColor);
		}
		color = this.lightenDarkenColor(regularColor, amount);
		shape.setAttribute("data-" + key + "-color", color);
		return color;
	},

	rgbToHex: function (rgb) {
		rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
		return (rgb && rgb.length === 4) ? "#" +
			("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
			("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
		("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
	},

	lightenDarkenColor: function (colorCode, amount) {
		var usePound = false;

		if (colorCode[0] === "#") {
			colorCode = colorCode.slice(1);
			usePound = true;
		}

		var num = parseInt(colorCode, 16);

		var r = (num >> 16) + amount;
		r = this.fixColorBoundaries(r);

		var b = ((num >> 8) & 0x00FF) + amount;
		b = this.fixColorBoundaries(b);
		
		var g = (num & 0x0000FF) + amount;
		g = this.fixColorBoundaries(g);
		
		return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
	},

	fixColorBoundaries: function (color) {
		if (color > 255) {
			return 255;
		}
		if (color < 0) {
			return 0;
		}
		return color;
	},

	/**
	 * Clear selection on the map.
	 */
	clearUI: function () {
		for (i = 0; i < this._stateArray.length; i++) {
			this.fillShape(this._stateArray[i], this.fillColorsEnum.regular);
		}
	},

	resetUI: function () {
		this._toClick = null;
		clearTimeout(this._blinkTimer1);
		clearTimeout(this._blinkTimer2);
		this._blinkCounter = 0;
		this.clearUI();
		this.removeAllListeners();
		this.addAllListeners();
	},

	removeAllListeners: function () {
		for (i = 0; i < this._stateArray.length; i++) {
			this.stopObserveAllListeners(this._stateArray[i]);
		}
	},

	setTaskText: function (string) {
		this.el.down(".mia-questionText").update(string);
	},

	setToClick: function (number, string) {
		// turn off blink
		this._toClick = number;
		this.setTaskText(string);
	},

	initGame: function (toClick, taskText) {
		this.addAllListeners();
		this._toClick = toClick;
		this.setTaskText(taskText);
		this._gameInitialized = true;
		this.hideProgressIndicator();
	},

	pushStateIntoArray: function (state) {
		this._stateArray.push(state);
	},

	addListenerToState: function (state) {
		this.safeObserve(state, "mousedown", function () { this.stateClicked(state); }.bindAsEventListener(this));
		this.safeObserve(state, "mouseover", function () { this.fillShape(state, this.fillColorsEnum.hover); state.style.cursor = "pointer"; }.bindAsEventListener(this));
		this.safeObserve(state, "mouseout", function () { this.fillShape(state, this.fillColorsEnum.regular); }.bindAsEventListener(this));
	},

	stateClicked: function (state) {
		var found = false;
		if (this._toClick != null) {
			for (i = 0; i < this._itemArray.length; i++) {
				if (this._itemArray[i] == state.id && this._geoIdsArray[i] == this._toClick) {
					found = true;
					this.stopObserveAllListeners(state);
					state.style.cursor = "default";
					this.fillShape(state, this.fillColorsEnum.correct);
					this.userAnswer(true);
					break;
				}
			}
		}
		
		if (!found) {
			this.removeAllListeners();
			this.fillShape(state, this.fillColorsEnum.incorrect);
			
			for (i = 0; i < this._geoIdsArray.length; i++) {
				if (this._geoIdsArray[i] == this._toClick) {
					this.blink(this.svgDoc.getElementById(this._itemArray[i]));
					break; 
				}
			}
			this.userAnswer(false);
		}
	},
	
	blink: function(state) {
		if (this._blinkCounter < this.nrOfBlinks) {
			this.blinker(state, this._blinkCounter % 2);
			this._blinkCounter++;
			var _this = this;
			window.setTimeout(function () { this.blink(state); }.bind(_this), _this.blinkLength);
		} else {
			this.blinker(state, true);
			this._blinkCounter = 0;
			this.clearUI();
		}
	},
	
	blinker: function (state, normal) {
		var opacity = normal ? 1 : 0;
		state.style.opacity = opacity;
	},

	initializeScript: function () {
		var svg = this.el.down('.map');	
		this.addViewBoxIfNotExist(svg);
		this.removeWidthAndHeightAttributes(svg);
		this.svgDoc = svg.contentDocument;
		svg.style.opacity = 1;

		if (typeof (console) != "undefined") {
			console.log("GamesFlashlessGeography.initializeScript", this);
		}

		this.initFlashGame(true);
	},

	addViewBoxIfNotExist: function(svg) {
		var svgElement = svg.contentDocument.querySelector('svg');
		if (svgElement.hasAttribute('viewBox')) {
			return;
		}

		var width = svgElement.getAttribute('width');
		var height = svgElement.getAttribute('height');
		if (width === null || height === null) {
			return;
		}

		svgElement.setAttribute('viewBox', '0 0 ' + width + ' ' + height);		
	},

	// There are cases when the SVG overflows its parent container due to fixed width and height attributes.
	// Therefore, we remove these attributes after they have been added to the viewBox 
	// so the SVG can scale correctly within its parent container with default attributes of height=100% and width=100%.
	removeWidthAndHeightAttributes: function(svg) {
		var svgElement = svg.contentDocument.querySelector('svg');
		svgElement.removeAttribute('width');
		svgElement.removeAttribute('height');
	},
	
	addAllListeners: function() {
		for (i = 0; i < this._itemArray.length; i++) {
			var item = this.svgDoc.getElementById(this._itemArray[i]);
			
			if ((item == null || typeof(item) == "undefined")) {
				throw new Error("Error in geography game configuration: id \"" + this._itemArray[i] + "\" was not found in the SVG. (Found " + this._itemArray.length + " items in the database.) Please select Feedback at the bottom of this page to let our tech team know about this problem.")
			}
			
			this.pushStateIntoArray(item);
			this.addListenerToState(item);
			this.fillShape(item, this.fillColorsEnum.regular);
		}
	}
});

GamesFlashlessGeography.addMethods(ProgressIndicatingControl);
