/// <reference path="DOMAttached.js" />
/// <reference path="gameUtils/EasierLevel.js" />

var TypingGame = Class.create(DOMAttached, {
	initialize: function ($super, el, options) {
		$super(el, options);
		Typing = {};
		Typing.id = el;
		Typing.cjson = options;

		var levelRunning = false;

		//global vars
		//svg consts
		var svg, svgHeight, svgWidth;
		var numRects, rectWidth;
		var keySize, keyOffset;
    
		//background svg groups
		var upperBackground, lowerBackground;
		var keys, handGroup, handOpacity;
		var typingText, highlight, textBoxWidth;
    
		//audio keyfeedback
		var keySounds, wrongKeySound;
    
		//timer pie chart
		var pieCenter, piePath, pieObject, pieArc;
    
		//keyboard thumb side tracker
		var lastHighlightLeft;
    
		//counter sizes
		var counterUpperHeight;
		var counterUpperWidth;
		var counterLowerWidth;
		var counterLowerHeight;
    
		//pointer to keyboard overlay
		var keyboardOverlay = null;
    
		//hiddenInput for mobile typing
		var hiddenInput;
    
		//progress displays
		var levelUpScreen;
		var levelUpScreenId;
		var levelText;
		var nextMedalArea;
		var nextMedalIcon;
		var nextMedalText;
		var medalBoxImage;
		var progressBar;
		var questionsLeft;
		var rootElement;

		if (EasierLevelInstance == null) {
			EasierLevelInstance = new EasierLevel(this.options, this.safeObserve.bind(this));
		}

		if (GenericSoundsInstance == null) {
			GenericSoundsInstance = new GenericSounds(this.options.playFeedbackSound);
		}

		jQuery(document).ready(function() {
			if (!document.querySelector(".mia-LevelUpScreen")) {
				new window.Vue({el: this.el.down(".mia-LegacyLevelUpScreen")});
			}
			if (global.isTouchDevice()) {
				this.el.down(".mia-svgGameContainer").hide();
				new window.Vue({el: this.el.down(".mia-TypingGameTouchDevices")});
				showGameFeedbackButton(false);
				waitForElementToBePresent(".mia-haveKeyboardButton").then(function(element) { this.safeObserve(element, 'click', onHaveKeyboardButtonClick.bind(this)); }.bind(this));
			} else {
				initializeTypingGame(this);
			}
		}.bind(this))

		function initializeTypingGame(context) {
			rootElement = context;
			init();
			if (options.flavour == "text") {
				rootElement.initializeTypingTexts();
			} else if (options.flavour == "letter") {
				rootElement.initializeTypingLetters();
			}
		}

		function onHaveKeyboardButtonClick() {
			this.el.down(".mia-svgGameContainer").show();
			this.el.down(".mia-TypingGameTouchDevices").hide();
			showGameFeedbackButton(true);
			scaleToContainerWidth();

			initializeTypingGame(this);
		}
    
		function init() {
			initParams();
			setupMobileTyping();
			setupAudio();
			setupProgessDisplays();
    
			drawWallpaper();
			drawAccuracyBox();
			if (Typing.cjson.gameName == "typing1") {
				drawCounter();
				drawScoreBox();
			} else {
				drawTextBox();
				drawTimerBox();
			}
			
			drawKeyboard();
			drawHand();
			drawKeyboardOverlay();
		}
    
		function initParams() {
			Typing.SpeedSelection = "normal";
			Typing.svg = svg = d3.select("svg.map");
			Typing.svgHeight = svgHeight = svg.node().parentNode.clientHeight;
			Typing.svgWidth = svgWidth = svg.node().parentNode.clientWidth;
			svg.attr("overflow", "hidden");
			numRects = 16;
			Typing.rectWidth = rectWidth = svgWidth / numRects;
			keySize = 40;
			keyOffset = 15;
			handOpacity = 0.6;
    
			//constants
			upperBackground = svg.append("g").attr("class", "upperBackground");
			lowerBackground = svg.append("g").attr("class", "lowerBackground")
				.attr("transform", "translate(0 " + svgHeight / 2 + ")");
			Typing.counterUpperHeight = counterUpperHeight = svgHeight * 0.30;
			Typing.counterUpperWidth = counterUpperWidth = rectWidth * 12.5;
			Typing.counterLowerWidth = counterLowerWidth = rectWidth * 14.5;
			Typing.counterLowerHeight = counterLowerHeight = svgHeight * 0.40;
		}
    
		function setupMobileTyping() {
			hiddenInput = document.getElementById(Typing.cjson["hiddenInput"]);
			hiddenInput.focus();
			svg.on("click", function () {
				hiddenInput.focus();
			});
		}
    
		function setupAudio() {
			keySounds = [];
			for (var i = 0; i < 5; i++) {
				keySounds[i] = new Audio(Typing.cjson["keysound" + (i + 1)]);
			}
			wrongKeySound = new Audio(Typing.cjson["wrongkeysound"]);
		}
    
		function playKeySound() {
			if (GenericSoundsInstance != null
				&& GenericSoundsInstance.playFeedbackSound) {
				var soundNum = Math.floor(Math.random() * 5);
				keySounds[soundNum].load();
				keySounds[soundNum].play();
			}
		}
    
		function playWrongKeySound() {
			if (GenericSoundsInstance != null
				&& GenericSoundsInstance.playFeedbackSound) {
				wrongKeySound.load();
				wrongKeySound.play();
			}
		}

		function setupProgessDisplays() {
			levelUpScreenId = '#levelUpScreen';
			waitForElementToBePresent(levelUpScreenId).then(function() { 
				levelUpScreen = jQuery(levelUpScreenId); 
				levelUpScreen.css("display", "none");
				showGameFeedbackButton(true);
				levelUpScreen.find(".levelUpButton").on("click", levelUpClick); 
			});
			levelText = d3.select(".mia-levelText");
			nextMedalArea = d3.select(".nextMedalArea");
			nextMedalIcon = d3.select(".medalIcon");
			nextMedalText = d3.select(".nextMedalText");
			medalBoxImage = d3.select(".mia-medalBox").select("img");
			progressBar = d3.select(".mia-progressBar");
			questionsLeft = d3.select(".questionsLeft");
			waitForElementToBePresent(".mia-easierLevelConfirmButton").then(function(element) { element.on("click", Typing.OnLevelJumpClick) });
    
			function levelUpClick(event) {
				event.preventDefault();
				if (!Typing.LevelJSON["medal"].endsWith("games/medals/medal_none.svg")) {
					updateNextMedalArea(Typing.LevelJSON);
					updateMedalBox();
					Typing.LevelJSON["medal"] = "games/medals/medal_none.svg";
				} else {
					Typing.ResetInterface();
				}
				levelUpScreen.css("display", "none");
				showGameFeedbackButton(true);
				rootElement.el.down(".mia-ProgressSection").show();
				rootElement.el.down(".mia-svgGameContainer").show();
			}
		}

		Typing.OnLevelJumpClick = onLevelJumpClick;
		function onLevelJumpClick() {
			EasierLevelInstance.setEasierLevel(
				Typing.cjson.levelJumpUrl, 
				function () { 
					d3.select(".mia-EasierLevelModal").select(".mia-img").node().click(); 
					Typing.GetInitialParams(); 
				}
			); 
		}

		Typing.InitializeEasierLevel = initializeEasierLevel;
		function initializeEasierLevel(json) {
			EasierLevelInstance.initializeEasierLevel(json);
		}
    
		Typing.SetLevelText = setLevelText;
		function setLevelText(curLevel) {
			levelText.text("Level: " + curLevel);
		}
		
		Typing.UpdateNextMedalArea = updateNextMedalArea;
		function updateNextMedalArea(levelJSON) {
			if (levelJSON["nextMedalLevel"] > 0 && !levelJSON["noNextMedal"]) {
				nextMedalArea.style("display", "inline-block");
				nextMedalIcon.attr("src", levelJSON["nextMedalImageSource"]);
				nextMedalText.text("after Level: " + levelJSON["nextMedalLevel"]);
			} else {
				nextMedalArea.style("display", "none");
			}
		}
		
		function updateMedalBox(medalSource) {
			if (!Typing.LevelJSON["medal"].endsWith("games/medals/medal_none.svg")) {
				medalBoxImage.attr("src", Typing.LevelJSON["medal"]);
				medalBoxImage.style("display", "inline");
			}
		}
    
		Typing.SetProgressBar = setProgressBar;
		function setProgressBar(percentage, numberQuestionsLeft) {
			progressBar.style("width", percentage + "%");
			if (numberQuestionsLeft == 1) {
				questionsLeft.text(Typing.cjson.questionsLeftMessageSingular);
			} else if (numberQuestionsLeft || numberQuestionsLeft == 0) {
				questionsLeft.style("display", "flex");
				questionsLeft.text(Typing.cjson.questionsLeftMessage.replace('{num}', numberQuestionsLeft));
			} else {
				questionsLeft.style("display", "none");
			}
		}
    
		Typing.ShowLevelUpOverlay = showLevelUpOverlay;
		function showLevelUpOverlay(success, level, amount, medalName, robotPictureSrc, robotWrongSrc, medalRecived, medalRobotSrc) {
			levelUpScreen.css("display", "flex");
			showGameFeedbackButton(false);
			rootElement.el.down(".mia-svgGameContainer").hide();
			rootElement.el.down(".mia-ProgressSection").hide();
			var previousLevel = level - 1;

			if (success) {
				levelUpScreen.find(".title").text(Typing.cjson.trCongratulations);
				levelUpScreen.find(".levelUpButton").text(Typing.cjson.levelUpContinue);
				if (medalRecived) {
					Typing.ShowEndingScreen(previousLevel, medalName, amount, medalRobotSrc);
					window.invalidateSelectedTask();
				} else {
					levelUpScreen.find(".endingScreenButtons").css("display", "none");
					levelUpScreen.find(".levelButtons").css("display", "flex");
					levelUpScreen.find(".image").attr("src", robotPictureSrc);
					if (!Typing.LevelJSON["medal"].endsWith("games/medals/medal_none.svg")) { 
						levelUpScreen.find(".header").text(Typing.cjson.trHeaderWithMedal.replace("{number}", previousLevel).replace("{medalType}", medalName));
						levelUpScreen.find(".description").text(Typing.cjson.trDescriptionWithMedal.replace("{number}", previousLevel).replace('{coins}', amount).replace("{medalType}", medalName));
					} else {
						levelUpScreen.find(".header").text(Typing.cjson.trHeaderWithoutMedal.replace("{number}", previousLevel));
						levelUpScreen.find(".description").text(Typing.cjson.trDescriptionWithoutMedal.replace("{number}", previousLevel).replace('{coins}', amount));
					}

					if (level === 1) {
						levelUpScreen.find(".levelButtons").css("display", "none");
						levelUpScreen.find(".endGameButtons").css("display", "flex");
						levelUpScreen.find('.continueButton').css("display", "inline-block");
					}
				}
			} else {
				levelUpScreen.find(".title").text(Typing.cjson.trOops);
				levelUpScreen.find(".image").attr("src", robotWrongSrc);
				levelUpScreen.find(".description").text(Typing.cjson.trLevelNotPassed);
				levelUpScreen.find(".header").text(Typing.cjson.youLostTitleText);
				levelUpScreen.find(".levelUpButton").text(Typing.cjson.retryButtonText);
			}
		}

		Typing.ShowEndingScreen = showEndingScreen;
		function showEndingScreen(level, medalName, amount, robotSrc) {
			waitForElementToBePresent(levelUpScreenId).then(function() {
				levelUpScreen.css("display", "flex");
				showGameFeedbackButton(false);
				rootElement.el.down(".mia-svgGameContainer").hide();
				rootElement.el.down(".mia-ProgressSection").hide();
				
				levelUpScreen.find(".endingScreenButtons").css("display", "flex");
				levelUpScreen.find(".levelButtons").css("display", "none");
				levelUpScreen.find(".title").text(Typing.cjson.trCongratulations);
				levelUpScreen.find(".image").attr("src", robotSrc);
				levelUpScreen.find(".header").text(Typing.cjson.trHeaderWithMedal.replace("{number}", level).replace("{medalType}", medalName));
				levelUpScreen.find(".description").text(Typing.cjson.trDescriptionWithMedal.replace("{number}", level).replace('{coins}', amount).replace("{medalType}", medalName));
				levelUpScreen.find(".repeatButton").on("click", Typing.OnRepeatMedalClick);
			});
		}

		Typing.OnRepeatMedalClick = onRepeatMedalClick;
		function onRepeatMedalClick(event) {
			event.preventDefault();
			new Ajax.Request(new UrlTemplate(Typing.cjson.endingScreenRepeatMedalUrl).url, {
				method: 'get',
				onSuccess: Typing.GetInitialParams
			});
			levelUpScreen.css("display", "none");
			showGameFeedbackButton(true);
			rootElement.el.down(".mia-svgGameContainer").show();
			rootElement.el.down(".mia-ProgressSection").show();
		}

		function showGameFeedbackButton(show) {
			var feedbackButton = document.querySelector(".mia-GameFeedbackButton");

			if (feedbackButton !== null) {
				feedbackButton.style.display = show ? "flex" : "none";
			}
		}
		function drawWallpaper() {
			var stripeBits = d3.range(0, 16, 1);
			var stripes = upperBackground.selectAll("rect").data(stripeBits).enter().append("rect")
				.attr("width", rectWidth)
				.attr("height", svgHeight / 2)
				.attr("x", function (d) { return d * rectWidth; })
				.attr("fill", function (d) { return d % 2 == 0 ? "rgb(191,122,233)" : "rgb(214,170,241)"; });
		}
    
		Typing.DrawStartButton = drawStartButton;
		function drawStartButton() {
			if (!upperBackground.selectAll(".startMenu").empty()) {
				return;
			}
			var width = 7.5 * rectWidth;
			var height = 2 * rectWidth
			var posX = rectWidth * 1.75;
			var posY = rectWidth * 0.75;
			var buttonContainer = upperBackground.append("g")
				.attr("transform", "translate(" + posX + " " + posY +")")
				.attr("class", "startMenu");
    
			var startButton = buttonContainer.append("g")
				// We don't use 'click' event here because it does not work well with v-click-outside directive
				.on("mouseup", onStartClick);
			startButton.append("rect")
				.attr("width", width)
				.attr("height", height)
				.attr("rx", 15)
				.attr("ry", 15)
				.style("fill", "rgba(7,134,0,1)")
				.style("stroke", "rgba(156,39,12,1)").style("stroke-width", 3);
			startButton.append("text")
				.text("Start")
				.attr("font-size", rectWidth)
				.style("fill", "white")
				.attr("x", width / 2)
				.attr("y", height * 2 / 3)
				.attr("text-anchor", "middle");
    
			function onStartClick() {
				Typing.Start();
				buttonContainer.remove();
			}
			
			var speedButtonsTranslateY = height + rectWidth / 4;
			var speedButtons = buttonContainer.append("g")
				.attr("transform", "translate(0 " + speedButtonsTranslateY + ")");
			var slowButton = speedButtons.append("g")
				.attr("transform", "translate(0 0)")
				.on("click", function() { onSpeedClick(slowButton); });
			slowButton.append("rect");
			slowButton.append("text")
				.text("Slow");
			
			var normalButtonTranslateX = width / 3 + 0.125 * rectWidth;
			var normalButton = speedButtons.append("g")
				.attr("transform", "translate(" + normalButtonTranslateX + " 0)")
				.on("click", function() { onSpeedClick(normalButton); });
			normalButton.append("rect");
			normalButton.append("text")
				.text("Normal");
    
			var fastButtonTranslateY = 0.125 * rectWidth + 2 * width / 3;
			var fastButton = speedButtons.append("g")
				.attr("transform", "translate(" + fastButtonTranslateY + " 0)")
				.on("click", function() { onSpeedClick(fastButton); });
			fastButton.append("rect");
			fastButton.append("text")
				.text("Fast");
    
			buttonContainer.selectAll("g").attr("cursor", "pointer");
			speedButtons.selectAll("rect")
				.attr("width", width / 3 - 0.5 *rectWidth/3)
				.attr("height", rectWidth)
				.attr("rx", 15)
				.attr("ry", 15)
				.style("fill", "beige")
				.style("stroke", "rgba(156,39,12,1)").style("stroke-width", 3);
			speedButtons.selectAll("text")
				.attr("font-size", rectWidth / 2)
				.attr("x", width / 6 - 0.125 * rectWidth)
				.attr("y", rectWidth * 2 / 3)
				.attr("text-anchor", "middle");
    
			switch (Typing.SpeedSelection) {
				case "Slow":
					onSpeedClick(slowButton);
					break;
				case "Fast":
					onSpeedClick(fastButton);
					break;
				default:
					onSpeedClick(normalButton);
			}
    
			function onSpeedClick(button) {
				speedButtons.selectAll("rect")
					.style("fill", "beige");
				button.select("rect")
					.style("fill", "lightgreen");
				Typing.SpeedSelection = button.select("text").text();
			}
		}
    
		function drawCounter() {
			var counterTop = upperBackground.append("polygon")
				.attr("points", "-1, "
					+ counterUpperHeight + " " + counterUpperWidth + ", "
					+ counterUpperHeight + " " + counterLowerWidth + ", "
					+ counterLowerHeight + " -1, "
					+ counterLowerHeight)
				.attr("fill", "rgb(239,213,188)")
				.attr("stroke", "rgb(144,91,53)").attr("stroke-width", 1);
			var counterSide = upperBackground.append("rect")
				.attr("width", counterLowerWidth)
				.attr("height", 10)
				.attr("y", counterLowerHeight)
				.attr("fill", "rgb(144,91,53)");
			var counterStand = upperBackground.append("rect")
				.attr("width", counterLowerWidth - 10)
				.attr("height", svgHeight / 2 - counterLowerHeight - 10)
				.attr("y", counterLowerHeight + 10)
				.attr("fill", "rgb(167,71,27)");
		}
    
		function wrap(text, width, maxLetters) {
			text.each(function () {
				var text = d3.select(this),
					words = text.text().split(/\s+/).reverse(),
					word,
					line = [],
					lineNumber = 0,
					lineHeight = 1.1, // ems
					y = text.attr("y"),
					x = text.attr("x"),
					letterCount = 0,
					tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", "0em");
				while (word = words.pop()) {
					line.push(word);
					tspan.text(line.join(" "));
					if (tspan.node().getComputedTextLength() > width) {
						line.pop();
						tspan.text(line.join(" ").substring(0, maxLetters - letterCount));
						letterCount += line.join(" ").length + 1;
						if (letterCount >= maxLetters) {
							return;
						}
						line = [word];
						tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + "em").text(word);
					}
				}
				tspan.text(line.join(" ").substring(0, maxLetters - letterCount));
			});
		}
    
		function drawTextBox() {
			textBoxWidth = 10.5 * rectWidth;
			var textBox = upperBackground.append("g")
				.attr("transform", "translate(" + rectWidth / 4 + " " + rectWidth / 4 + ")");
			var textRect = textBox.append("rect")
				.attr("width", textBoxWidth)
				.attr("height", 5 * rectWidth)
				.attr("fill", "beige")
				.attr("stroke", "rgba(156,39,12,1)").attr("stroke-width", 2);
    
			typingText = textBox.append("text")
				.attr("x", rectWidth / 4)
				.attr("y", 3 * rectWidth / 4)
				.attr("font-size", rectWidth / 2)
				.call(wrap, textBoxWidth - 0.5 * rectWidth);
    
			highlight = textBox.append("text")
				.attr("x", rectWidth / 4)
				.attr("y", 3 * rectWidth / 4)
				.attr("font-size", rectWidth / 2)
				.attr("fill", "lightgray")
				.call(wrap, textBoxWidth - 0.5 * rectWidth);
		}
    
		Typing.UpdateTextBox = updateTextBox;
		function updateTextBox(content) {
			typingText.text(content).call(wrap, textBoxWidth - 0.5 * rectWidth, 1000000);
			highlight.text("");
		}
    
		Typing.ColorizeTextBox = colorizeTextBox;
		function colorizeTextBox(content, position) {
			highlight.text(content)
				.call(wrap, textBoxWidth - 0.5 * rectWidth, position);
		}
    
		function drawTimerBox() {
			var width = 4.5 * rectWidth;
			var height = 3.5 * rectWidth;
			var radius = Math.min(width, height) / 2;
			var timerBox = upperBackground.append("g")
				.attr("transform", "translate(" + 11.25 * rectWidth + " " + 1.75 * rectWidth + ")");
			var timerRect = timerBox.append("rect")
				.attr("width", width)
				.attr("height", height)
				.attr("fill", "beige")
				.attr("stroke", "rgba(156,39,12,1)").attr("stroke-width", 2);
    
			pieCenter = timerBox.append("g")
				.attr("transform", "translate(" + width / 2 + " " + height / 2 + ")");
    
			var initialData = [
				{ frame: 0 },
				{ frame: 100 }
			];
    
			pieObject = d3.pie()
				.value(function (d) { return d.frame; })
				.sort(null);
		
			pieArc = d3.arc()
				.innerRadius(0)
				.outerRadius(radius - 10);
    
			piePath = pieCenter.datum(initialData).selectAll("path")
					.data(pieObject)
				  .enter().append("path")
					.attr("fill", function (d, i) { return i == 0 ? "beige" : "rgba(156,39,12,1)"; })
					.attr("d", pieArc)
					.each(function (d) { this._current = d; }); // store the initial angles
		}
    
		Typing.UpdateTimer = updateTimer;
		function updateTimer(framesLeft, framesPast) {
			var updatedData = [
				{ frame: framesPast },
				{ frame: framesLeft }
			];
    
			pieCenter.datum(updatedData);
			piePath = piePath.data(pieObject); // compute the new angles
			piePath.transition().duration(0).attrTween("d", arcTween); // redraw the arcs
    
			function arcTween(a) {
				var i = d3.interpolate(this._current, a);
				this._current = i(0);
				return function (t) {
					return pieArc(i(t));
				};
			}
		}
    
		function drawAccuracyBox() {
			var accuracyBox = upperBackground.append("g")
				.attr("transform", "translate(" + 11.25 * rectWidth + " " + 0.25 * rectWidth + ")");
			var accuracyRect = accuracyBox.append("rect")
				.attr("width", 4.5 * rectWidth)
				.attr("height", rectWidth)
				.attr("fill", "beige")
				.attr("stroke", "rgba(156,39,12,1)").attr("stroke-width", 2);
    
			Typing.AccuracyLabel = accuracyBox.append("text")
				.text("Accuracy: 100%")
				.attr("x", rectWidth / 4)
				.attr("y", rectWidth / 2)
				.attr("alignment-baseline", "central")
				.attr("font-size", rectWidth / 2);
		}
    
		function drawScoreBox() {
			var scoreBox = upperBackground.append("g")
				.attr("transform", "translate(" + 11.25 * rectWidth + " " + 1.75 * rectWidth + ")");
			var scoreRect = scoreBox.append("rect")
				.attr("width", 4.5 * rectWidth)
				.attr("height", rectWidth)
				.attr("fill", "beige")
				.attr("stroke", "rgba(156,39,12,1)").attr("stroke-width", 2);
    
			Typing.ScoreLabel = scoreBox.append("text")
				.text("Score: 0")
				.attr("x", rectWidth / 4)
				.attr("y", rectWidth / 2)
				.attr("alignment-baseline", "central")
				.attr("font-size", rectWidth / 2);
		}
    
		function drawKeyboard() {
			var keyboardBG = lowerBackground.append("rect")
			.attr("width", "100%")
			.attr("height", "50%")
			.attr("fill", "beige");
    
			keys = lowerBackground.append("g").attr("class", "keys");
    
			var topRow = keys.append("g").attr("class", "topRow");
			var midRow = keys.append("g").attr("class", "midRow");
			var botRow = keys.append("g").attr("class", "botRow");
			var spaceBar = keys.append("g").attr("class", "spaceBar");
    
			var topRowLetters = [
				{ "key": "Q", "finger": 0 },
				{ "key": "W", "finger": 1 },
				{ "key": "E", "finger": 2 },
				{ "key": "R", "finger": 3 },
				{ "key": "T", "finger": 3 },
				{ "key": "Y", "finger": 4 },
				{ "key": "U", "finger": 4 },
				{ "key": "I", "finger": 5 },
				{ "key": "O", "finger": 6 },
				{ "key": "P", "finger": 7 },
				{ "key": "[", "finger": 7 },
				{ "key": "]", "finger": 7 }
			];
			var midRowLetters = [
				{ "key": "A", "finger": 0 },
				{ "key": "S", "finger": 1 },
				{ "key": "D", "finger": 2 },
				{ "key": "F", "finger": 3 },
				{ "key": "G", "finger": 3 },
				{ "key": "H", "finger": 4 },
				{ "key": "J", "finger": 4 },
				{ "key": "K", "finger": 5 },
				{ "key": "L", "finger": 6 },
				{ "key": ";", "finger": 7 },
				{ "key": "'", "finger": 7 },
				{ "key": "\\", "finger": 7 }
			];
			var botRowLetters = [
				{ "key": "Z", "finger": 0 },
				{ "key": "X", "finger": 1 },
				{ "key": "C", "finger": 2 },
				{ "key": "V", "finger": 3 },
				{ "key": "B", "finger": 3 },
				{ "key": "N", "finger": 4 },
				{ "key": "M", "finger": 4 },
				{ "key": ",", "finger": 5 },
				{ "key": ".", "finger": 6 },
				{ "key": "/", "finger": 7 }
			];
			var spaceBarLetter = [{ "key": " ", "finger": 8 }];
    
			var topRowKeys = topRow.selectAll("g").data(topRowLetters).enter().append("g")
				.attr("transform", function (d, i) { return "translate(" + (20 + i * (keyOffset + keySize)) + " 20)"; });
			var midRowKeys = midRow.selectAll("g").data(midRowLetters).enter().append("g")
				.attr("transform", function (d, i) { return "translate(" + (40 + i * (keyOffset + keySize)) + " " + (20 + (keyOffset + keySize)) + ")"; });
			var botRowKeys = botRow.selectAll("g").data(botRowLetters).enter().append("g")
				.attr("transform", function (d, i) { return "translate(" + (60 + i * (keyOffset + keySize)) + " " + (20 + 2 * (keyOffset + keySize)) + ")"; });
			var spaceBarKey = spaceBar.selectAll("g").data(spaceBarLetter).enter().append("g")
				.attr("transform", function (d, i) { return "translate(140 " + (20 + 3 * (keyOffset + keySize)) + ")"; });
    
			var keyRects = keys.selectAll("g").selectAll("g").append("rect")
				.attr("width", keySize)
				.attr("height", keySize)
				.attr("fill", "beige")
				.attr("stroke", function (d) { return getColorForFinger(d.finger); }).attr("stroke-width", 4);
			var keyLabels = keys.selectAll("g").selectAll("g").append("text")
				.text(function (d) { return d.key; })
				.attr("font-size", keySize / 2)
				.attr("text-anchor", "middle")
				.attr("alignment-baseline", "central")
				.attr("transform", "translate(" + keySize / 2 + " " + keySize / 2 + ")");
    
			var spaceBarKeyG = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == " " ? this : null) : null; });
    
			spaceBarKeyG.select("rect")
				.attr("width", 5 * (keyOffset + keySize));
		}
    
		function getFingerIndex(letter) {
			var key = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == letter ? this : null) : null; });
			if (key.data()[0] === undefined) {
				return 9; //can't match finger
			} else {
				return key.data()[0].finger;
			}
		}
    
		function getColorForFinger(fingerIndex) {
			return d3.schemeCategory10[fingerIndex];
		}
    
		Typing.GetColorForLetter = getColorForLetter;
		function getColorForLetter(letter) {
			return getColorForFinger(getFingerIndex(letter));
		}
    
		function drawKeyboardOverlay() {
			var fontsize = 30;
			keyboardOverlay = lowerBackground.append("g")
				.attr("opacity", 0);
			overlayRect = keyboardOverlay.append("rect")
				.attr("width", "100%")
				.attr("height", "50%")
				.attr("fill", "beige");
			overlayText = keyboardOverlay.append("text")
				.attr("text-anchor", "middle")
				.attr("x", "50%")
				.attr("y", "25%")
				.attr("font-size", fontsize);
			overlayText.append("tspan")
				.text("Now try to do it without the fingers shown.");
			overlayText.append("tspan")
				.text("Only look at the letters on the screen!")
				.attr("dy", fontsize * 1.2)
				.attr("x", "50%");
		}
    
		Typing.ShowKeyboard = showKeyboard;
		function showKeyboard(bool) {
			if(bool) {
				keyboardOverlay.attr("opacity", 0);
			} else {
				keyboardOverlay.attr("opacity", 1);
			}
		}
    
		Typing.CorrectKeyHit = correctKeyHit;
		function correctKeyHit(key) {
			playKeySound();
			var printableKeyHit = keys.selectAll("rect").select(function (d) { return d.key == key ? this : null; })
					.attr("fill", "green")
					.transition().duration(500)
					.attr("fill", "beige");
			return !printableKeyHit.empty();
		}
    
		Typing.WrongKeyHit = wrongKeyHit;
		function wrongKeyHit(key) {			
			var isPrintableKeyHit = !keys.selectAll("rect").select(function (d) { return d.key == key ? this : null; })
					.attr("fill", "red")
					.transition().duration(500)
					.attr("fill", "beige")
					.empty();
			if (isPrintableKeyHit) {
				playWrongKeySound();
			}
			return isPrintableKeyHit;
		}
    
		function drawHand() {
			handGroup = lowerBackground.append("g")
				.attr("opacity", handOpacity);
			drawPalms();
			drawFingers();
			drawThumb(true);
			drawThumb(false);
		}
    
		function drawPalms() {
			for (var i = 0; i < 2; i++) {
				var palm = handGroup.append("ellipse");
				palm
					.attr("cx", (20 + 55*i) +"%").attr("cy", "62%")
					.attr("rx", "17%").attr("ry", "17%")
					.attr("fill", "orange")
					.attr("class", "palm" + i);
			}
		}
    
		function drawFingers() {
			var letters = "ASDFJKL;"
			for (var fingerIndex = 0; fingerIndex < 8; fingerIndex++) {
				var finger = handGroup.append("line");
				fingerTip = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == letters[fingerIndex] ? this : null) : null; });
				var x = getTransformation(fingerTip)[0];
				var y = getTransformation(fingerTip)[1];
				finger
					.attr("x1", x + keySize / 2).attr("y1", y + keySize / 2)
					.attr("x2", (fingerIndex > 3 ? svgWidth / 2 + 100 : 60) + (fingerIndex % 4) * 50).attr("y2", svgHeight / 2)
					.attr("stroke", "orange").attr("stroke-width", keySize - 5).attr("stroke-linecap", "round")
					.attr("class", "finger" + fingerIndex);
			}
		}
    
		function drawThumb(isLeftOne) {
			var prefix = isLeftOne ? "left" : "right"
			var spaceBarKey = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == " " ? this : null) : null; });
			var x = getTransformation(spaceBarKey)[0];
			var y = getTransformation(spaceBarKey)[1];
			thumb = handGroup.append("line");
			thumb.attr("class", prefix + "Thumb");
			thumb
				.attr("x1", x + (isLeftOne ? 3.5 : 5.5) * keySize).attr("y1", y + keySize / 2)
				.attr("x2", (isLeftOne ? 250 : 450)).attr("y2", svgHeight / 2 + keySize)
				.attr("stroke-width", keySize - 5).attr("stroke-linecap", "round");
		}
    
		Typing.ResetHand = resetHand;
		function resetHand() {
			"ASDFJKL; ".split('').forEach(function (letter) {
				updateFingers(letter, false);
			});
		}
    
		function updateFingers(letter, highlight) {
			if (letter == " ") {
				updateThumbs(highlight);
				return;
			}
    
			var fingerTip = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == letter ? this : null) : null; });
			if (fingerTip.data()[0] === undefined) {
				return;
			}
			var fingerIndex = fingerTip.data()[0].finger;
			var x = getTransformation(fingerTip)[0];
			var y = getTransformation(fingerTip)[1];
			var finger = handGroup.select(".finger" + fingerIndex);
			finger
				.transition().duration(300)
				.attr("x1", x + keySize / 2).attr("y1", y + keySize / 2)
				.attr("stroke", highlight ? "green" : "orange");
			if (highlight) {
				lastHighlightLeft = fingerIndex < 4;
			}
		}
    
		function updateThumbs(highlight) {
			if (highlight) {
				updateThumb(!lastHighlightLeft, true);
			} else {
				updateThumb(true, false);
				updateThumb(false, false);
			}
		}
    
		function updateThumb(isLeftOne, highlight) {
			var prefix = isLeftOne ? "left" : "right"
			var spaceBarKey = keys.selectAll("g").selectAll("g").select(function (d) { return d ? (d.key == " " ? this : null) : null; });
			var x = getTransformation(spaceBarKey)[0];
			var y = getTransformation(spaceBarKey)[1];
			var thumb = handGroup.select("." + prefix + "Thumb");
			thumb
				.transition().duration(300)
				.attr("stroke", highlight ? "green" : "orange");
		}
    
		Typing.ResetHandHighlightNextKey = resetHandHighlightNextKey;
		function resetHandHighlightNextKey(letter) {
			resetHand();
			updateFingers(letter.toUpperCase(), true);
		}
    
		function getTransformation(node) {
			return readval = node.attr("transform").split('(')[1].split(')')[0].split(' ').map(Number);
		}
	},
	
	initializeTypingLetters: function () {
		var fps = 60;
		var frame = -1;
		var scoopSpawnSeconds = 2;
		var scoopVelocity = 100;
		var letters = "QWERTYUIOPASDFGHJKLZXCVBNM";
		var scoopRadius = 20;
		var correctKeys = 0;
		var wrongKeys = 0;
		var missedKeys = 0;
		var levelLoopHandle = null;
		var remainingLetters = 50;
    
		//levelParams (+ some arbitrary fallback values)
		var showKeyboard = true;
		var maxLevel = 50;
		var successPercentage = 85;
		var numberOfLetters = 50;
		var isLevelUp = false;
		var level = 1;
		var amountWon = 0;
		var newReward = 0;
		var openProdding = "";
		var success = false;
    
		//initialization code starts here
		var scoopGroups = Typing.svg.append("g").attr("class", "scoops");
    
		d3.select("body").on("keydown", function () {
			keyDown(d3.event.key.toUpperCase());
		});
		if (Typing.cjson.endingScreenShown) {
			Typing.ShowEndingScreen(Typing.cjson.endingScreenLevel, Typing.cjson.endingScreenMedalName, Typing.cjson.endingScreenAmountOfScoops, Typing.cjson.endingScreenRobotSrc);
		} else {
			getInitialParams();
		}
		//initialization code ends here
    
		Typing.ResetInterface = resetInterface;
		function resetInterface() {
			stop();
			Typing.SetProgressBar(0, numberOfLetters);
			Typing.ScoreLabel.text("Score: 0");
			Typing.AccuracyLabel.text("Accuracy: 100%");
			Typing.ResetHand();
			scoopGroups.selectAll(".scoopContainer").remove();
			Typing.DrawStartButton();
		}
    
		Typing.GetInitialParams = getInitialParams;
		function getInitialParams() {
			new Ajax.Request(new UrlTemplate(Typing.cjson.processAnswerUrl).r('PLH_TimeNeeded', 0).r('PLH_KeystrokesCorrect', 0).r('PLH_KeystrokesIncorrect', 0).r('PLH_success', false).r('PLH_Status', 1).r('PLH_itemId', -1).url, {
				method: 'get',
				onSuccess: onInitSuccess
			});
		}
    
		function loadLevelParams(req, finished) {
			var levelJSON = req.responseJSON;
			Typing.LevelJSON = levelJSON;
			//reset game vars
			frame = -1;
			correctKeys = 0;
			wrongKeys = 0;
			missedKeys = 0;
			//load level params
			showKeyboard = levelJSON["showKeyboard"];
			letters = levelJSON["letters"];
			scoopVelocity = levelJSON["speed"] * 10;
			scoopSpawnSeconds = 3;
			maxLevel = levelJSON["maxLevel"];
			remainingLetters = numberOfLetters = levelJSON["numberOfLetters"];
			isLevelUp = level != levelJSON["level"];
			level = levelJSON["level"];
			amountWon = levelJSON["amount"];
			newReward = levelJSON["newreward"];
			openProdding = levelJSON["openProdding"];
			success = levelJSON["success"];

			// Injecting a value to make it available for EasierLevel.js.
			levelJSON["medalMinLevel"] = Typing.cjson.medalMinLevel;

			Typing.SetLevelText(level);
			Typing.ShowKeyboard(showKeyboard);
			Typing.InitializeEasierLevel(levelJSON);
			Typing.UpdateNextMedalArea(levelJSON);
			if (!finished) {
				Typing.SetProgressBar(0, numberOfLetters);
			}
		}
    
		function submitResults(timeNeeded, correctKeys, incorrectKeys, success, status) {
			new Ajax.Request(new UrlTemplate(Typing.cjson.processAnswerUrl).r('PLH_TimeNeeded', timeNeeded).r('PLH_KeystrokesCorrect', correctKeys).r('PLH_KeystrokesIncorrect', incorrectKeys).r('PLH_success', success).r('PLH_Status', status).r('PLH_itemId', -1).url, {
				method: 'get',
				onSuccess: onLevelFinished
			});
		}
    
		function onInitSuccess(req) {
			resetInterface();
			loadLevelParams(req, false);
		}
    
		function onLevelFinished(req) {
			loadLevelParams(req, true);
			Typing.ShowLevelUpOverlay(success, level, amountWon, req.responseJSON.medalName, req.responseJSON.robotPictureSrc, req.responseJSON.robotWrongSrc, req.responseJSON.medalRecived, req.responseJSON.medalRobotPictureSrc);
		}
    
		function updateKeyboardVisual() {
			if (scoopGroups.select(".scoopContainer").empty()) {
				Typing.ResetHand();
			} else {
				Typing.ResetHandHighlightNextKey(getRightMostLetter());
			}
		}
    
		function getRightMostLetter() {
			return scoopGroups.select(".scoopContainer").attr("attachedLetter");
		}
    
		function updateScore() {
			var totalKeys = correctKeys + wrongKeys + missedKeys;
			if (totalKeys > 0) {
				Typing.ScoreLabel.text("Score: " + correctKeys);
				Typing.AccuracyLabel.text("Accuracy: " + (100 * correctKeys / (totalKeys)).toFixed(0) + "%");
			}
			Typing.SetProgressBar(100 * (correctKeys + missedKeys) / numberOfLetters, numberOfLetters - correctKeys - missedKeys);
			if (correctKeys + missedKeys == numberOfLetters) {
				stop();
				endOfLevelCheck(correctKeys / totalKeys);
			}
		}
    
		function endOfLevelCheck(accuracy) {
			submitResults(frame * fps, correctKeys, wrongKeys, 100 * accuracy >= successPercentage, 2, onLevelFinished);
		}
    
		function keyDown(key) {
			if (!levelRunning) {
				// level has not started yet
				return;
			}
			if (d3.event.target.tagName.toUpperCase() == "INPUT" && d3.event.target.type.toUpperCase() == "TEXT") {
				return; //skip keydown logic if focus is on textbox entry
			}
			if (key == " " || key == "BACKSPACE") {
				d3.event.preventDefault();
			}
			scoopHit = scoopGroups.selectAll(".scoopContainer").filter(function () { return d3.select(this).attr("attachedLetter") == key; }).filter(function (d, i) { return i == 0; })
				.attr("class", "hitScoopContainer")
				.attr("fill", "green")
				.attr("stroke", "green").attr("stroke-width", 3)
				.transition().duration(500)
				.attr("stroke-width", 0)
				.attr("fill", "rgba(0,0,0,0)").remove();
			if (scoopHit.empty()) {
				if (Typing.WrongKeyHit(key)) {
					wrongKeys++;
				}
			} else {
				Typing.CorrectKeyHit(key);
				correctKeys++;
				updateKeyboardVisual();
			}
			updateScore();
		}
    
		function getrandomLetter() {
			return letters.charAt(Math.floor(Math.random() * letters.length))
		}
    
		function addNewScoops(letter) {
			var randHeight = (Math.random() - 0.5) * Typing.svgHeight * 0.1;
			var rad = scoopRadius /1.41;
			var dx = scoopRadius /2;
			var newScoop = scoopGroups.append("g")
				.attr("class", "scoopContainer")
				.attr("transform", "translate(0 " + Typing.svgHeight * 0.35 + ")")
				.attr("attachedLetter", letter)
				.attr("xTransform", 0)
				.attr("yTransform", Typing.svgHeight * 0.3);
			newScoop.append("circle")
				.attr("cx", 0)
				.attr("cy", randHeight)
				.attr("r", scoopRadius)
				.attr("fill", "beige");
			newScoop.append("polygon")
				.attr("points", (-rad) + ", "
					+ (rad + randHeight) + ", "
					+ rad + ", "
					+ (rad + randHeight) + ", "
					+ dx + ", "
					+ (2 * rad + randHeight) + ", "
					+ (-dx) + ", "
					+ (2 * rad + randHeight))
				.attr("fill", Typing.GetColorForLetter(letter));
			newScoop.append("text")
				.text(letter)
				.attr("font-size", scoopRadius)
				.attr("x", 0 - scoopRadius / 4)
				.attr("y", randHeight + scoopRadius / 4);
			newScoop.append()
			updateKeyboardVisual();
		}
    
		function updateScoops() {
			var velPerFrame = scoopVelocity / fps;
			scoopGroups.selectAll("g")
				.attr("xTransform", function () { return parseFloat(d3.select(this).attr("xTransform")) + velPerFrame; })
				.attr("transform", function () { return "translate(" + d3.select(this).attr("xTransform") + " " + d3.select(this).attr("yTransform") + ")"; });
			var missedScoops = scoopGroups.selectAll(".scoopContainer")
				.filter(function () { return parseFloat(d3.select(this).attr("xTransform")) > (Typing.counterLowerWidth + Typing.counterUpperWidth) / 2;});
			if (!missedScoops.empty()) {
				missedScoops
					.attr("class", "missedScoopContainer")
					.attr("fill", "red")
					.attr("stroke", "red").attr("stroke-width", 3)
					.transition().duration(500)
					.attr("stroke-width", 0)
					.attr("fill", "rgba(0,0,0,0)").remove();
				updateKeyboardVisual();
				missedKeys++;
				updateScore();
			}
		}
    
		function levelLoop() {
			if (!document.getElementById(Typing.id)) {
				stop();
			}
			
			frame++;
			if (frame % (fps * scoopSpawnSeconds) == 0 && remainingLetters > 0) {
				nextLetter = getrandomLetter();
				addNewScoops(nextLetter);
				remainingLetters--;
			}
			updateScoops();
		}
    
		Typing.Start = start;
		function start() {
			if (Typing.SpeedSelection == "Fast") {
				scoopSpawnSeconds = 1;
			} else if (Typing.SpeedSelection == "Normal") {
				scoopSpawnSeconds = 2;
			} else if (Typing.SpeedSelection == "Slow") {
				scoopSpawnSeconds = 3;
			}
			levelLoopHandle = setInterval(function () { levelLoop(); }, 1000 / fps);
			levelRunning = true;
		}
    
		function stop() {
			clearInterval(levelLoopHandle);
			levelRunning = false;
		}
    },

    initializeTypingTexts: function() {
		var fps = 60;
		var frame = -1;
		var correctKeys = 0;
		var wrongKeys = 0;
		var levelLoopHandle = null;
    
		//levelParams (+ some arbitrary fallback values)
		var showKeyboard = true;
		var maxLevel = 50;
		var successPercentage = 85;
		var numberOfLetters = 50;
		var isLevelUp = false;
		var level = 1;
		var amountWon = 0;
		var newReward = 0;
		var openProdding = "";
		var success = false;
		var typingText = "";
		var levelRunning = false;
		var speed = 45;
		var frameThreshold = 1000000;
	
		//initialization code starts here
		d3.select("body").on("keydown", function () {
			keyDown(d3.event.key.toUpperCase());
		});
		if (Typing.cjson.endingScreenShown) {
			Typing.ShowEndingScreen(Typing.cjson.endingScreenLevel, Typing.cjson.endingScreenMedalName, Typing.cjson.endingScreenAmountOfScoops, Typing.cjson.endingScreenRobotSrc);
		} else {
			getInitialParams();
		}
		//initialization code ends here
    
		Typing.ResetInterface = resetInterface;
		function resetInterface() {
			stop();
			Typing.SetProgressBar(0, numberOfLetters);
			Typing.AccuracyLabel.text("Accuracy: 100%");
			Typing.ResetHand();
			Typing.DrawStartButton();
		}
    
		Typing.GetInitialParams = getInitialParams;
		function getInitialParams() {
			new Ajax.Request(new UrlTemplate(Typing.cjson.processAnswerUrl).r('PLH_TimeNeeded', 0).r('PLH_KeystrokesCorrect', 0).r('PLH_KeystrokesIncorrect', 0).r('PLH_success', false).r('PLH_Status', 1).r('PLH_itemId', -1).url, {
				method: 'get',
				onSuccess: onInitSuccess
			});
		}
    
		function loadLevelParams(req, finished) {
			var levelJSON = req.responseJSON;
			Typing.LevelJSON = levelJSON;
			//reset game vars
			frame = -1;
			correctKeys = 0;
			wrongKeys = 0;
			//load level params
			showKeyboard = levelJSON["showKeyboard"];
			maxLevel = levelJSON["maxLevel"];
			isLevelUp = level != levelJSON["level"];
			level = levelJSON["level"];
			amountWon = levelJSON["amount"];
			newReward = levelJSON["newreward"];
			openProdding = levelJSON["openProdding"];
			success = levelJSON["success"];
			typingText = req.responseJSON.text.trim();
			numberOfLetters = typingText.length;
			speed = req.responseJSON["speed"];

			// Injecting a value to make it available for EasierLevel.js.
			levelJSON["medalMinLevel"] = Typing.cjson.medalMinLevel;

			Typing.SetLevelText(level);
			Typing.ShowKeyboard(showKeyboard);
			Typing.InitializeEasierLevel(levelJSON);
			Typing.UpdateNextMedalArea(levelJSON);
			if (!finished) {
				Typing.SetProgressBar(0, numberOfLetters);
			}
		}
    
		function submitResults(timeNeeded, correctKeys, incorrectKeys, success, status) {
			new Ajax.Request(new UrlTemplate(Typing.cjson.processAnswerUrl).r('PLH_TimeNeeded', timeNeeded).r('PLH_KeystrokesCorrect', correctKeys).r('PLH_KeystrokesIncorrect', incorrectKeys).r('PLH_success', success).r('PLH_Status', status).r('PLH_itemId', -1).url, {
				method: 'get',
				onSuccess: onLevelFinished
			});
		}
    
		function onInitSuccess(req) {
			resetInterface();
			loadLevelParams(req, false);
		}
    
		function onLevelFinished(req) {
			loadLevelParams(req, true);
			Typing.ShowLevelUpOverlay(success, level, amountWon, req.responseJSON.medalName, req.responseJSON.robotPictureSrc, req.responseJSON.robotWrongSrc, req.responseJSON.medalRecived, req.responseJSON.medalRobotPictureSrc);
		}
    
		function updateKeyboardVisual() {
			if (correctKeys >= numberOfLetters) {
				Typing.ResetHand();
			} else {
				Typing.ColorizeTextBox(typingText, correctKeys);
				Typing.ResetHandHighlightNextKey(typingText.charAt(correctKeys));
			}
		}
    
		function updateScore() {
			var totalKeys = correctKeys + wrongKeys;
			if (totalKeys > 0) {
				var accuracy = (100 * correctKeys / (totalKeys)).toFixed(0);
				Typing.AccuracyLabel.text("Accuracy: " + accuracy + "%");
			}
			Typing.SetProgressBar(100 * correctKeys / numberOfLetters, numberOfLetters - correctKeys);
			if (correctKeys == numberOfLetters) {
				stop();
				endOfLevelCheck(correctKeys / totalKeys, false);
			}
		}
    
		function endOfLevelCheck(accuracy, timeOut) {
			submitResults(frame * fps, correctKeys, wrongKeys, !timeOut && (100 * accuracy >= successPercentage), 2, onLevelFinished);
		}
    
		function keyDown(key) {
			if (d3.event.target.tagName.toUpperCase() == "INPUT" && d3.event.target.type.toUpperCase() == "TEXT") {
				return; //skip keydown logic if focus is on textbox entry
			}
			if (key == " " || key == "BACKSPACE") {
				d3.event.preventDefault();
			}
			if (!levelRunning) {
				return;
			}
			if (key != typingText.charAt(correctKeys).toUpperCase()) {
				if (Typing.WrongKeyHit(key)) {
					wrongKeys++;
				}
			} else {
				Typing.CorrectKeyHit(key);
				correctKeys++;
				updateKeyboardVisual();
			}
			updateScore();
		}
    
		function levelLoop() {
			if (!document.getElementById(Typing.id)) {
				stop();
			}
			
			frame++;
			Typing.UpdateTimer(frameThreshold - frame, frame);
			if (frame >= frameThreshold) {
				endOfLevelCheck(correctKeys / (correctKeys + wrongKeys), true);
				stop();
			}
		}
    
		Typing.Start = start;
		function start() {
			levelRunning = true;
			Typing.UpdateTextBox(typingText);
			updateKeyboardVisual();
			if (Typing.SpeedSelection == "Fast") {
				speed += 25;
			} else if (Typing.SpeedSelection == "Normal") {
				;
			} else if (Typing.SpeedSelection == "Slow") {
				speed = Math.max(1, speed - 15);
			}
			frameThreshold = 60 * fps * typingText.length / speed;
			levelLoopHandle = setInterval(function () { levelLoop(); }, 1000 / fps);
		}
    
		function stop() {
			levelRunning = false;
			Typing.UpdateTextBox("");
			clearInterval(levelLoopHandle);
		}
	},

	onMobileRedirect: function () {
		window.location.href = this.options.mobileRedirectURL;
	},
});
